.BreadCrumbWrapper {
    background-color: #efefef;
    padding: 1rem 0;
}

.BreadCrumbP {
    padding: 0;

    ol {
        margin: 0;
        font-size: 0.85rem;

        @media (max-width: 991px) {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #333;
            transition: color 0.3s ease;

            @media (max-width: 991px) {
                display: inline-block;
            }

            &:hover {
                color: var(--primary-color);
            }

            svg {
                margin-right: 0.5rem;
            }
        }
    }
}
